import { CentralDeNotificacao } from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/centralDeNotificacao.js'

class PlanilhaCentralDeNotificacao {
  static async downloadItem (item) {
    const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(item.id)
    const url = notificacaoCompleta.detalhe
    const nomeOriginal = this.extrairNomeArquivo(url)
    const novoNomeArquivo = this.modificarNomeArquivo(nomeOriginal)
    this.salvarLocalmente(url, novoNomeArquivo)
  }

  static extrairNomeArquivo (url) {
    const partesUrl = url.split('/')
    const nomeArquivo = partesUrl[partesUrl.length - 1]
    return nomeArquivo
  }

  static modificarNomeArquivo (nomeOriginal) {
    const indiceInicio = nomeOriginal.indexOf('_')
    let indiceFimDesejado = 0
    if (indiceInicio === -1) {
      return nomeOriginal
    }
    indiceFimDesejado = Math.min(indiceInicio + 1 + 49, nomeOriginal.length)
    let novoNome = nomeOriginal.substring(indiceInicio + 1, indiceFimDesejado)
    novoNome = novoNome.replace(/%20/g, ' ')
    novoNome += '.xlsx'
    novoNome = novoNome.replace(/[\x7F-\x9F]/g, '')
    return novoNome
  }

  static salvarLocalmente (url, nomeLocal) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const urlBlob = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = urlBlob
        a.download = nomeLocal
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
      .catch((error) => console.error('Erro ao baixar o arquivo:', error))
  }
}

export { PlanilhaCentralDeNotificacao }
