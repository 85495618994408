import { CentralDeNotificacao } from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/centralDeNotificacao.js'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'
class BalanceteCentralDeNotificacao {
  static async downloadItem (item) {
    const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(item.id)
    const detalhe = notificacaoCompleta.detalhe
    const nome = this.extrairNomeArquivo(detalhe)
    const urlParaPrepararDownload = `/api/v1/arquivos/gerar-url/download?fileName=${nome}`
    const respostaParaPrepararDownload = await service.get(urlParaPrepararDownload)

    return respostaParaPrepararDownload.url
  }

  static extrairNomeArquivo (detalhe) {
    const detalheObj = JSON.parse(detalhe)

    const nomeDoArquivo = detalheObj.nomeDoArquivo

    return nomeDoArquivo
  }
}

export { BalanceteCentralDeNotificacao }
