class Pageable {
  constructor (obj) {
    this.page = obj?.page || 0
    this.size = obj?.size || 20
    this.sort = obj?.sort ? new Sort(obj.sort) : new Sort()
  }
}

class Sort {
  constructor (obj) {
    this.orders = []
    if (obj?.orders) {
      obj.orders.forEach((item) => this.orders.push(new Order(item)))
    }
  }
}

class Order {
  constructor (obj) {
    this.direction = obj?.direction || 'ASC'
    this.ignoreCase = obj?.ignoreCase || false
    this.nullHandling = obj?.nullHandling || 'NATIVE'
    this.property = obj?.property || ''
  }
}

class Page {
  constructor (obj) {
    this.content = obj?.content || []
    this.pageable = obj?.pageable ? new Pageable(obj.pageable) : new Pageable()
    this.total = obj?.total || 0
  }
}

export { Page, Pageable, Order, Sort }
