<template>
  <Modal :title="tituloModal" :active="modalActive" size="normal" width="768px" @close="cancelar()">
    <slot name="acimaBoxInconsistencia" />
    <div v-if="props.exibeBoxDetalhe" id="detalhesModal" data-group="box-detalhe-inconsistencia" ref="modal" style="outline: none" class="container detalhes">
      <p data-group="txt-detalhe-inconsistencia" style="text-wrap: pretty" v-html="detalheModal"></p>
    </div>
    <template #footer>
      <ButtonAjuda link-ajuda="https://ajuda.alterdata.com.br/display/POD/Central+de+Notificacoes" title="Atalho: ALT + ?" style="margin-right: auto" />
      <Button title="Atalho: Esc" caption="Fechar" class="button" @click="cancelar()" />
    </template>
  </Modal>
</template>

<script setup>

import { ref, defineEmits, defineProps, onMounted, computed } from 'vue'

import Modal from '@alterdata/component-vue/Modal'
import Button from '@alterdata/component-vue/Button'

import { FuncoesGenericas } from './model/funcoesGenericas.js'

import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import { CentralDeNotificacao } from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/centralDeNotificacao.js'
import Constantes from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/constantes.js'
import { AjustesEmBlocoCentralDeNotificacao } from '@packonline/packonline-reutilizaveis-frontend/Utils/metodosLogAjustesEmBloco.js'

const props = defineProps({
  idProcesso: {
    type: Number,
    required: true
  },
  modalActive: {
    type: Boolean,
    default: false
  },
  exibeBoxDetalhe: {
    type: Boolean,
    default: true
  },
  identificadorOperacao: {
    type: String,
    required: true
  }
})
const emit = defineEmits(['cancelar'])

const tituloModal = computed(() => {
  switch (props.identificadorOperacao) {
    case Constantes.BALANCETE:
    case Constantes.RECIBOPAGAMENTO:
    case Constantes.FOLHAPAGAMENTO:
    case Constantes.BALANCO:
      return 'Inconsistências do relatório'
    case Constantes.EDICAOEMBLOCO:
      return 'Edição em bloco'
    case Constantes.IMPORTACAOPLANILHA:
      return 'Inconsistências da importação'
    case Constantes.IMPORTACAODELANCAMENTOSANTERIORESFUNCIONARIOS:
    case Constantes.IMPORTACAODELANCAMENTOSANTERIORESCONTRIBUINTES:
    case Constantes.IMPORTACAODEREMUNERACAODOCADASTRO:
      return 'Detalhes da importação'
    default:
      return ''
  }
})

const detalheModal = ref('')

onMounted(() => {
  if (props.exibeBoxDetalhe) {
    _carregarDetalhesDeAcordoComOIdentificadorDaOperacao()
  }
})

async function _carregarDetalhesDeAcordoComOIdentificadorDaOperacao () {
  const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(props.idProcesso)

  switch (props.identificadorOperacao) {
    case Constantes.BALANCETE: {
      if (isValidJson(notificacaoCompleta.detalhe)) {
        detalheModal.value = retornarObjJsonFormatado(notificacaoCompleta)
      } else {
        detalheModal.value = retornarDetalheFormatado(notificacaoCompleta.detalhe.toString())
      }
      break
    }
    case Constantes.EDICAOEMBLOCO: {
      if (AjustesEmBlocoCentralDeNotificacao.jsonDeDetalhesValido(notificacaoCompleta.detalhe)) {
        const jsonDetalhesNotificaoAjustesEmBloco = JSON.parse(notificacaoCompleta.detalhe)
        _montarDetalheModalAPartirDoJsonDeNotificaoAjustesEmBloco(jsonDetalhesNotificaoAjustesEmBloco)
      }
      break
    }
    case Constantes.IMPORTACAOPLANILHA: {
      const jsonFile = FuncoesGenericas.retornarJsonPuro(notificacaoCompleta.detalhe)
      const logArr = jsonFile.logRotinasAutomaticas.log
      detalheModal.value = FuncoesGenericas.transformarLinhasDoLog(logArr)
      break
    }
    case Constantes.IMPORTACAODELANCAMENTOSANTERIORESFUNCIONARIOS:
    case Constantes.IMPORTACAODELANCAMENTOSANTERIORESCONTRIBUINTES:
    case Constantes.IMPORTACAODEREMUNERACAODOCADASTRO: {
      detalheModal.value = retornarDetalheFormatado(notificacaoCompleta.detalhe)
      break
    }
    case Constantes.RECIBOPAGAMENTO: {
      montarDetalheGenerico(notificacaoCompleta)
      break
    }
    case Constantes.FOLHAPAGAMENTO: {
      montarDetalheGenerico(notificacaoCompleta)
      break
    }
    case Constantes.BALANCO: {
      const jsonFile = FuncoesGenericas.retornarJsonPuro(notificacaoCompleta.detalhe)
      const logArr = jsonFile.logRotinasAutomaticas.log
      detalheModal.value = FuncoesGenericas.transformarLinhasDoLog(logArr)
      break
    }
  }
}

function retornarObjJsonFormatado (completo) {
  const jsonFile = JSON.parse(completo.detalhe.replace(/\\/g, '\\\\'))

  for (const key in jsonFile) {
    if (key.startsWith('log')) {
      return jsonFile[key].replace(/\\/g, '\\')
    }
  }
}

function retornarDetalheFormatado (detalhe) {
  return detalhe.replace(/\r\n/g, '<br>')
}

function montarDetalheGenerico (notificacaoCompleta) {
  const jsonFile = FuncoesGenericas.retornarJsonPuro(notificacaoCompleta.detalhe)

  if (Array.isArray(jsonFile.logRotinasAutomaticas.log)) {
    detalheModal.value = FuncoesGenericas.transformarLinhasDoLog(jsonFile.logRotinasAutomatica.log)
  } else {
    detalheModal.value = FuncoesGenericas.transformarLinhasDoLog(jsonFile.logRotinasAutomaticas)
  }
}

function cancelar () {
  emit('cancelar')
}

function isValidJson (obj) {
  try {
    JSON.parse(obj.replace(/\\/g, '\\\\'))
    return true
  } catch (e) {
    return false
  }
}

function _montarDetalheModalAPartirDoJsonDeNotificaoAjustesEmBloco (json) {
  detalheModal.value = AjustesEmBlocoCentralDeNotificacao.montarDetalheModalAPartirDoJsonDeNotificao(json)
}
</script>

<style lang="scss" scoped>
@use '@alterdata/theme-bulma';

.detalhes {
  background-color: #f5f5f5;
  border: 1px solid #d0d0d0;
  min-height: 12.5rem;
  max-height: 20rem;
  white-space: nowrap;
  text-align: left;
  padding: 1rem;
  overflow: auto;
}
</style>
