<template>
    <Modal
    :title="tituloModal"
    size="normal"
    style="z-index: 1001"
    width="1000px"
    :active="true"
    @close="cancelar"
  >
    <div id="logInconsistenciasd" style="margin-bottom: 5%">
      <DataTableBasico
        :key="dataKey"
        ref="datatablebasico"
        :p-status="status"
        :p-data-source="dataSource"
        :p-data="logInconsistencias"
        :p-hide-button-more="hideButtonMore"
        :div-style="divStyleComputed"
        :custom-search="false"
        :next-page="_carregarListagem"
        :ativar-atalho-delete="false"
        :ativar-atalho-enter="false"
        :p-show-buttons="false"
        :p-search="false"
        :paginacao="false"
        custom-style="height:100%"
      >
      <template #new-item>
            <div class="pr-0" style="margin-right: -3px">
              <p class="paragraph" data-group="text-importacao-erro" style="max-width: 40rem">Nenhuma conta do plano de contas foi importada devido às inconsistências abaixo</p>
            </div>
          </template>
      </DataTableBasico>
    </div>
    <template v-slot:footer>
      <ButtonAjuda
        :title="this.shortkeys.MSG_KEY_AJUDA"
        :link-ajuda="linkAjudaImportacao"
      />
      <Button
        :title="shortkeys.MSG_KEY_ESC"
        class="button"
        caption="Fechar"
        @click="cancelar"
      />
    </template>
  </Modal>
</template>

<script>
import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import Modal from '@alterdata/component-vue/Modal'
import { Page } from '@/models/page'
import Button from '@alterdata/component-vue/Button'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { mixinShortKeysGlobal } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-short-keys-global'
import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { mixin } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin'
import { mixinFocoPrimeiroElementoDataTable } from '@packonline/packonline-reutilizaveis-frontend/Utils/mixin-foco-primeiro-elemento-data-table'
import { CentralDeNotificacao } from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/centralDeNotificacao.js'

export default {
  name: 'InconsistenciaLog',
  components: {
    DataTableBasico,
    Modal,
    Button,
    ButtonAjuda
  },
  mixins: [mixin, mixinFocoPrimeiroElementoDataTable, mixinShortKeysGlobal],
  props: {
    idPlanoDeContas: {
      type: Number,
      required: true
    }
  },
  emits: ['cancelar'],
  data () {
    return {
      dataSource: {
        columns: [
          {
            name: 'conta',
            label: 'Conta no arquivo',
            style: 'width: 20%'
          },
          {
            name: 'mensagem',
            label: 'Inconsistência',
            styleItems: 'overflow-wrap: anywhere;white-space: ;text-overflow: '
          }
        ],
        selected: null,
        itensSelected: [],
        cursor: null,
        filter: () => this._carregarListagem(),
        select: (item) => console.log(item),
        loadMore: null
      },
      page: new Page(),
      mostrarModalInconsistencias: false,
      logInconsistencias: [],
      status: '',
      itemSelected: undefined,
      hideButtonMore: false,
      showTitle: true,
      mostrarModal: false,
      pagina: 0,
      dataKey: 0,
      shortkeys: shortkeys,
      linkAjudaImportacao: 'https://ajuda.alterdata.com.br/pod/contabil/cadastros-contabeis/criar-plano-de-contas',
      tituloModal: ''
    }
  },
  mounted () {
    this._carregarListagem()
  },
  computed: {
    divStyleComputed () {
      if (this.logInconsistencias.length > 0) {
        return 'height: 60vh'
      } else {
        return ''
      }
    }
  },
  methods: {
    async _carregarListagem (pagina = 0) {
      this.status = 'is-loading'
      this.logInconsistencias = []
      try {
        const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(this.$props.idPlanoDeContas)
        const objeto = JSON.parse(this.retornarDetalheFormatado(notificacaoCompleta.detalhe.toString()))
        objeto.logRetorno = JSON.parse(objeto.logRetorno)
        this.logInconsistencias = this.retornarObjJsonFormatado(objeto.logRetorno.logRotinasAutomaticas)
        this.tituloModal = objeto.tituloModal
        this.page = new Page()
        this.page.pageable.size = this.logInconsistencias.length
        this.page.content = this.logInconsistencias
        this.page.total = this.logInconsistencias.length
        this.status = ''
        this.pagina = pagina
        if (this.page.total === 0) {
          this.status = ''
        }
      } catch (err) {
        console.error(err)
        this.status = 'is-danger'
      }
    },
    async isSelectedItem () {
      try {
        this.itemSelected = await utils.cloneDeep(this.dataSource.itensSelected)
      } catch {
        return false
      }
    },
    retornarDetalheFormatado (detalhe) {
      return detalhe.replace(/\r\n/g, '<br>')
    },
    retornarObjJsonFormatado (detalhe) {
      const jsonFile = detalhe
      const resultArray = []

      for (const key in jsonFile) {
        if (key.startsWith('leitura') && jsonFile[key].conta && jsonFile[key].mensagem) {
          const obj = {
            conta: jsonFile[key].conta,
            mensagem: jsonFile[key].mensagem
          }
          resultArray.push(obj)
        }
      }
      return resultArray
    },
    cancelar () {
      this.$emit('cancelar')
    },
    direcionaAjuda () {
      this.$emit('direciona-ajuda')
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@alterdata/theme-bulma';
.paragraph {
  font-size: 14px;
  font-weight: 400;
}
</style>
