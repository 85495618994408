<template>
  <div class="container">
    <div class="column is-full">
      <page-title>Início > Central de notificações</page-title>
      <div class="mt-0">
        <DataTableBasico
          :customHighlighters="customHighlighters"
          ref="datatablebasico"
          :p-status="status"
          :p-data-source="dataSource"
          :p-data="listaDeNotificacoesExibidasNoDataTable"
          :p-hide-button-more="false"
          :div-style="divStyleComputed"
          p-sorted-by="none"
          :ativar-atalho-delete="false"
          :ativar-atalho-enter="false"
          :p-show-buttons="false"
          :p-search="true"
        >
          <template v-slot:new-item>
            <div class="dropdown-container" style="height: 4rem">
              <DropDown
                data-group="dropdown-usuarios"
                style="margin-top: -2px"
                label="Filtrar notificações por usuário"
                @close="_ordenarListaDeUsuariosExibidosNoDropDown"
                :message="
                  listaDeUsuariosExibidosNoDropdown.filter(isChecked).length ===
                  0
                    ? 'Selecione um usuário'
                    : ''
                "
                :key="keyDinamicaParaReconstruirComponente"
                :label-placeholder="false"
                :caption="usuariosSelecionadosDropDown"
                :close-on-click-item="false"
              >
                <DropDownItem>
                  <a
                    data-group="limpar-selecao"
                    v-if="
                      listaDeUsuariosExibidosNoDropdown.filter(isChecked)
                        .length === listaDeUsuariosExibidosNoDropdown.length
                    "
                    @click="_limparSelecaoUsuarios"
                    style="text-decoration: underline"
                  >
                    Limpar a seleção
                  </a>
                  <a
                    data-group="selecionar-todos"
                    v-else
                    @click="_selecionarTodosUsuarios"
                    style="text-decoration: underline"
                  >
                    Selecionar todos
                  </a>
                </DropDownItem>
                <DropDownItem
                  v-for="(item, index) in listaDeUsuariosExibidosNoDropdown"
                  :key="index"
                >
                  <Checkbox
                    :data-group="'usuario-' + item.caption"
                    :caption="item.caption"
                    :message="item.message"
                    v-model="item.checked"
                  />
                </DropDownItem>
              </DropDown>
            </div>
            <Button
              data-group="btn-aplicar-filtro"
              @click="carregarNotificacoesComFiltro"
              :disabled="
                listaDeUsuariosExibidosNoDropdown.filter(isChecked).length === 0
              "
              :title="shortkeys.MSG_KEY_ALT_L"
              caption="Aplicar filtro"
              class="button is-secondary ml-3"
              type="secondary"
            >
            </Button>
            <span class="ml-4"
              >As notificações são excluídas automaticamente após 3 dias.</span
            >
          </template>
          <template #btns="{ item }">
            <div class="buttons is-right">
              <Button
                v-if="
                  apurarSeOBotaoDeveAparecerNoDataTable(
                    item.identificadorOperacao
                  )
                "
                datagroup="visualizar-status"
                icon="eye"
                caption="Visualizar"
                :disabled="estaDesativado(item)"
                class="button is-small is-pulled-right"
                type="secondary"
                @click="_clickDinamicoDeAcordoComOTipoDeItem(item)"
              >
              </Button>
            </div>
          </template>
          <template #statusTag="{ item }">
            <div class="has-text-centered">
              <Tag
                v-if="item.statusTag === 'Concluído'"
                class="is-positive is-light"
                caption="Concluído"
                style="font-style: normal !important"
              />
              <Tag
                v-else-if="item.statusTag === 'Falhou'"
                class="is-negative is-light"
                caption="Falhou"
                style="font-style: normal !important"
              />
              <Tag
                v-else-if="item.statusTag === 'Em curso'"
                caption="Em curso"
                class="tag-importando"
                style="font-style: normal !important"
              />
            </div>
          </template>
        </DataTableBasico>
      </div>
    </div>
  </div>
  <footer
    class="alt-modal-card-foot"
    style="margin-left: -1rem;position: absolute;width: 100vw;bottom: 1px"
  >
    <ButtonAjuda
      :title="shortkeys.MSG_KEY_AJUDA"
      :link-ajuda="LINK_AJUDA"
      class="ml-3"
    />
  </footer>

  <ModalInconsistenciasImportacaoPlanoDeContas
    v-if="showModalInconsistenciasPlanoDeContas"
    @cancelar="showModalInconsistenciasPlanoDeContas = false"
    :idPlanoDeContas="idParaObterNotificacaoCompleta"
  />
  <modalGenericoVisualizar
    v-if="showModalGenericoVisualizar"
    :modalActive="showModalGenericoVisualizar"
    @cancelar="showModalGenericoVisualizar = false"
    :idProcesso="idParaObterNotificacaoCompleta"
    :identificador-operacao="identificadorOperacaoParaAbrirModalVisualizar"
    :exibe-box-detalhe="!mostrarMensagemFalha"
  >
  <template #acimaBoxInconsistencia>
    <div v-if="identificadorOperacaoParaAbrirModalVisualizar === Constantes.IMPORTACAOPLANILHA">
    <p v-if="mostrarMensagemSucesso" class="mb-2" data-group="text-sucesso-inconsistencia">Importação realizada. Para visualizar os lançamentos importados <a :href="linkCliqueAqui" data-group="lnk-ver-lote" target="_self">clique aqui</a>. </p>
    <p v-if="mostrarMensagemFalha" data-group="text-erro-instabilidade" >Ocorreu uma instabilidade na importação. Tente novamente</p>
    <p v-if="!mostrarMensagemFalha && mostrarMensagemSucesso" class="mb-2" data-group="text-sucesso-inconsistencia-2">Alguns lançamentos não foram importados. Verifique as inconsistências abaixo:</p>
    <p v-if="!mostrarMensagemFalha && !mostrarMensagemSucesso" class="mb-2" data-group="text-inconsistencia">Nenhum lançamento foi importado. Verifique as inconsistências abaixo:</p>
    </div>
    <div v-else-if="mensagemCliqueAqui">
      <p class="mb-2" data-group="text-mensagem-clique-aqui">{{ mensagemCliqueAqui }}<a :href="linkCliqueAqui" data-group="link-clique-aqui" target="_self">clique aqui</a>.</p>
    </div>
  </template>
  </modalGenericoVisualizar>

  <modalGenericoVisualizarNovo
    v-if="showModalGenericoVisualizarNovo"
    :modalActive="showModalGenericoVisualizarNovo"
    @cancelar="showModalGenericoVisualizarNovo = false"
    :idProcesso="idParaObterNotificacaoCompleta"
    :identificador-operacao="identificadorOperacaoParaAbrirModalVisualizar"
    :exibe-box-detalhe="!mostrarMensagemFalha"
  >
  <template #acimaBoxInconsistencia>
    <div v-if="identificadorOperacaoParaAbrirModalVisualizar === Constantes.IMPORTACAOPLANILHA">
    <p v-if="mostrarMensagemSucesso" class="mb-2" data-group="text-sucesso-inconsistencia">Importação realizada. Para visualizar os lançamentos importados <a :href="linkCliqueAqui" data-group="lnk-ver-lote" target="_self">clique aqui</a>. </p>
    <p v-if="mostrarMensagemFalha" data-group="text-erro-instabilidade" >Ocorreu uma instabilidade na importação. Tente novamente</p>
    <p v-if="!mostrarMensagemFalha && mostrarMensagemSucesso" class="mb-2" data-group="text-sucesso-inconsistencia-2">Alguns lançamentos não foram importados. Verifique as inconsistências abaixo:</p>
    <p v-if="!mostrarMensagemFalha && !mostrarMensagemSucesso" class="mb-2" data-group="text-inconsistencia">Nenhum lançamento foi importado. Verifique as inconsistências abaixo:</p>
    </div>
    <div v-else-if="mensagemCliqueAqui">
      <p class="mb-2" data-group="text-mensagem-clique-aqui">{{ mensagemCliqueAqui }}<a :href="linkCliqueAqui" data-group="link-clique-aqui" target="_self">clique aqui</a>.</p>
    </div>
  </template>
  </modalGenericoVisualizarNovo>

  <ModalInconsistenciasProcessamentoFolha
    v-if="showModalInconsistenciasProcessamentoFolha"
    @cancelar="showModalInconsistenciasProcessamentoFolha = false"
    :statusProcessamentoFolha="statusProcessamentoFolha"
    :idProcessamentoFolha="idParaObterNotificacaoCompleta"
  />
</template>

<script setup>
import { env } from '@/env'

import { computed, ref, inject, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'

import Tag from '@alterdata/component-vue/Tag'
import Button from '@alterdata/component-vue/Button'
import DropDown from '@alterdata/component-vue/DropDown'
import Checkbox from '@alterdata/component-vue/Checkbox'
import DropDownItem from '@alterdata/component-vue/DropDownItem'

import { utils } from '@packonline/packonline-reutilizaveis-frontend/Utils/utils'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import PageTitle from '@packonline/packonline-reutilizaveis-frontend/components/PageTitle.vue'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import DataTableBasico from '@packonline/packonline-reutilizaveis-frontend/components/DataTableBasico.vue'
import { microServiceRequest } from '@packonline/packonline-reutilizaveis-frontend/core/services/micro-service-request'
import { CentralDeNotificacao } from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/centralDeNotificacao.js'
import Constantes from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/constantes.js'
import { BalanceteCentralDeNotificacao } from './Relatorios/Balancete/metodosBalancete.js'
import modalGenericoVisualizar from './modalGenericoVisualizar/index.vue'
import modalGenericoVisualizarNovo from './modalGenericoVisualizarNovo/index.vue'
import { FuncoesGenericas } from './modalGenericoVisualizar/model/funcoesGenericas'
import { CentralDeNotificacoes } from './centralDeNotificacoes.js'
import { PlanilhaCentralDeNotificacao } from './PlanilhaDeMovimento/metodosPlanilhaMovimento.js'
import ModalInconsistenciasImportacaoPlanoDeContas from './VisualizarPlanoDeContas/inconsistenciasImportacao.vue'
import ModalInconsistenciasProcessamentoFolha from './ProcessamentoFolha/ModalInconsistenciasProcessamentoFolha.vue'

const addShortcut = inject('addShortcut')
const setActiveSection = inject('setActiveSection')

const divStyleComputed = computed(() => {
  if (listaDeNotificacoesExibidasNoDataTable.value.length > 0) {
    return 'height: 80vh'
  } else {
    return ''
  }
})

const usuariosSelecionadosDropDown = computed(() => {
  if (listaDeUsuariosExibidosNoDropdown.value.filter(isChecked).length === 1) {
    return '1 usuário selecionado'
  }
  return `${listaDeUsuariosExibidosNoDropdown.value.filter(isChecked).length} usuários selecionados`
})

const LINK_AJUDA = 'https://ajuda.alterdata.com.br/display/POD/Central+de+Notificacoes'
const LINK_AJUDA_IMPORTACAO_PLANO_DE_CONTAS = 'https://ajuda.alterdata.com.br/pod/contabil/cadastros-contabeis/criar-plano-de-contas'
const LINK_AJUDA_PROCESSAMENTO_FOLHA = 'https://ajuda.alterdata.com.br/display/POD/Processar+Folha+de+Pagamento'

const router = useRouter()

const listaDeUsuarios = ref([])
const listaDeUsuariosExibidosNoDropdown = ref([])

const isChecked = item => item.checked
const status = ref('')
const keyDinamicaParaReconstruirComponente = ref('')

const listaDeNotificacoes = ref([])
const listaDeNotificacoesExibidasNoDataTable = ref([])
const listaDeNotificacoesNaoVisualizadas = ref([])

const idParaObterNotificacaoCompleta = ref({})
const identificadorOperacaoParaAbrirModalVisualizar = ref('')

const showModalInconsistenciasPlanoDeContas = ref(false)
const showModalGenericoVisualizar = ref(false)
const showModalGenericoVisualizarNovo = ref(false)
const showModalInconsistenciasProcessamentoFolha = ref(false)
const mostrarMensagemSucesso = ref(false)
const mostrarMensagemFalha = ref(false)
const linkCliqueAqui = ref('')
const mensagemCliqueAqui = ref('')

const dataSource = ref({
  columns: [
    {
      name: 'statusTag',
      label: 'Status',
      style: 'width: 12%',
      classes: 'has-text-centered',
      slot: true
    },
    {
      name: 'descricao',
      label: 'Descrição',
      style: 'width: 45%'
    },
    {
      name: 'tempoDecorrido',
      label: 'Tempo decorrido',
      style: 'width: 15%',
      format: CentralDeNotificacao.definirAQuantoTempoANotificacaoChegou
    },
    {
      name: 'usuario',
      label: 'Usuário',
      style: 'width: 15%'
    },
    {
      name: 'btns',
      slot: true
    }
  ],
  selected: null,
  isSelectedItem: [],
  cursor: null,
  loadMore: null
})

watch(showModalInconsistenciasPlanoDeContas, (newValue) => {
  if (newValue) {
    _ligarAtalhosModalVisualizar(LINK_AJUDA_IMPORTACAO_PLANO_DE_CONTAS)
  } else {
    _ligarAtalhosCentralNotificacoes()
  }
})

watch(showModalInconsistenciasProcessamentoFolha, (newValue) => {
  if (newValue) {
    _ligarAtalhosModalVisualizar(LINK_AJUDA_PROCESSAMENTO_FOLHA)
  } else {
    _ligarAtalhosCentralNotificacoes()
  }
})

watch(showModalGenericoVisualizar, (newValue) => {
  if (newValue) {
    _ligarAtalhosModalVisualizar(LINK_AJUDA)
  } else {
    _ligarAtalhosCentralNotificacoes()
  }
})

watch(showModalGenericoVisualizarNovo, (newValue) => {
  if (newValue) {
    _ligarAtalhosModalVisualizar(LINK_AJUDA)
  } else {
    _ligarAtalhosCentralNotificacoes()
  }
})

const retornarUsuarioLogado = () => {
  const cookies = ' ' + document.cookie
  const key = ' ' + 'Usuario' + '='
  const start = cookies.indexOf(key)

  if (start === -1) return ''

  const pos = start + key.length
  const last = cookies.indexOf(';', pos)

  if (last !== -1) return cookies.substring(pos, last)

  return cookies.substring(pos)
}

const apurarSeOBotaoDeveAparecerNoDataTable = (identificadorOperacao) => {
  return (identificadorOperacao === Constantes.IMPORTACAOPLANO) ||
    (identificadorOperacao === Constantes.EXPORTACAOPLANILHA) ||
    (identificadorOperacao === Constantes.BALANCETE ||
    identificadorOperacao === Constantes.IMPORTACAOPLANILHA ||
    identificadorOperacao === Constantes.EDICAOEMBLOCO ||
    identificadorOperacao === Constantes.PROCESSAMENTOFOLHA ||
    identificadorOperacao === Constantes.IMPORTACAODELANCAMENTOSANTERIORESFUNCIONARIOS ||
    identificadorOperacao === Constantes.IMPORTACAODELANCAMENTOSANTERIORESCONTRIBUINTES ||
    identificadorOperacao === Constantes.RECIBOPAGAMENTO ||
    identificadorOperacao === Constantes.FOLHAPAGAMENTO ||
    identificadorOperacao === Constantes.BALANCO ||
    identificadorOperacao === Constantes.IMPORTACAODEREMUNERACAODOCADASTRO)
}

const customHighlighters = (value, busca, item) => {
  const stringfied = '' + value

  busca = String(busca).trim()

  if (
    !utils
      .removeAcentos(stringfied)
      .toLowerCase()
      .includes(utils.removeAcentos(busca).toLowerCase())
  ) {
    return value
  }

  const init = utils
    .removeAcentos(stringfied)
    .toLowerCase()
    .indexOf(utils.removeAcentos(busca).toLowerCase())
  const end = init + busca.length
  const extractedValue = stringfied.substring(init, end)

  return stringfied.replace(
    extractedValue,
        `<span class="has-background-warning">${extractedValue}</span>`
  )
}

const carregarTodasNotificacoes = async () => {
  status.value = 'is-loading'
  const objetoCentralRetorno = await CentralDeNotificacao.monitorarTarefasDaCentralDeNotificacoes(retornarUsuarioLogado())

  listaDeNotificacoes.value = objetoCentralRetorno.listaDeTarefasDoUsuarioNaCentral
  listaDeNotificacoesNaoVisualizadas.value = objetoCentralRetorno.listaDeNotificacoesNaoVisualizadas
  _formatarObjetosDeNotificacaoParaExibirNoDataTable()

  status.value = ''
}

const carregarNotificacoesComFiltro = async () => {
  const usuariosSelecionados = listaDeUsuariosExibidosNoDropdown.value.filter(isChecked).map(usuario => usuario.caption)

  if (usuariosSelecionados.length > 0) {
    status.value = 'is-loading'
    const objetoCentralRetorno = await CentralDeNotificacao.monitorarTarefasDaCentralDeNotificacoes(usuariosSelecionados.toString())

    listaDeNotificacoes.value = objetoCentralRetorno.listaDeTarefasDoUsuarioNaCentral
    _formatarObjetosDeNotificacaoParaExibirNoDataTable()

    status.value = ''
  }
}

const carregarTodosUsuarios = async () => {
  listaDeUsuarios.value = await microServiceRequest.get(env.msPhd, '/api/v1/adusystem/todos-usuarios')
  _criarObjetoDeUsuarioParaDropdown()
}

const _selecionarTodosUsuarios = () => {
  listaDeUsuariosExibidosNoDropdown.value.forEach(usuario => {
    usuario.checked = true
  })
  _ordenarListaDeUsuariosExibidosNoDropDown()
}

const _limparSelecaoUsuarios = () => {
  listaDeUsuariosExibidosNoDropdown.value.forEach(usuario => {
    usuario.checked = false
  })
  _ordenarListaDeUsuariosExibidosNoDropDown()
}

const _formatarObjetosDeNotificacaoParaExibirNoDataTable = () => {
  listaDeNotificacoesExibidasNoDataTable.value = []

  listaDeNotificacoes.value.forEach(notificacao => {
    const obj = {
      id: notificacao.id,
      value: notificacao.value,
      status: notificacao.status,
      statusTag: notificacao.status === Constantes.ANDAMENTO ? 'Em curso'
        : notificacao.status === Constantes.ERRO ? 'Falhou'
          : notificacao.status === Constantes.SUCESSO ? 'Concluído'
            : notificacao.status === Constantes.INCONSISTENCIA ? 'Concluído' : '',

      descricao: `${notificacao.value}${CentralDeNotificacao.formatarStatusParaExibicao(notificacao.status)}`,
      tempoDecorrido: notificacao.dataHoraAtualizacao,
      dataHoraAtualizacao: notificacao.dataHoraAtualizacao,
      identificadorOperacao: notificacao.identificadorOperacao,
      usuario: notificacao.usuario
    }
    listaDeNotificacoesExibidasNoDataTable.value.push(obj)
  })
  listaDeNotificacoesExibidasNoDataTable.value.sort((a, b) => {
    const dataA = new Date(a.dataHoraAtualizacao)
    const dataB = new Date(b.dataHoraAtualizacao)
    return dataB - dataA
  })
}

const _criarObjetoDeUsuarioParaDropdown = () => {
  const usuarioLogado = retornarUsuarioLogado()

  listaDeUsuarios.value.forEach(usuario => {
    const obj = {
      caption: usuario.nmcurto,
      checked: usuarioLogado === usuario.nmcurto,
      message: usuarioLogado === usuario.nmcurto ? 'Usuário logado' : ''
    }
    listaDeUsuariosExibidosNoDropdown.value.push(obj)
  })
  _ordenarListaDeUsuariosExibidosNoDropDown()
}

const _ordenarListaDeUsuariosExibidosNoDropDown = () => {
  const usuarioLogado = retornarUsuarioLogado()

  listaDeUsuariosExibidosNoDropdown.value = listaDeUsuariosExibidosNoDropdown.value.slice().sort((a, b) => {
    if (a.caption === usuarioLogado) return -1
    if (b.caption === usuarioLogado) return 1

    if (a.checked && !b.checked) return -1
    if (!a.checked && b.checked) return 1

    return a.caption.localeCompare(b.caption)
  })

  keyDinamicaParaReconstruirComponente.value = new Date()
}

const _ligarAtalhosCentralNotificacoes = () => {
  const section = 'centralNotificacoes'
  setActiveSection(section)

  addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, () => {
    window.open(LINK_AJUDA, '_blank')
  })
  addShortcut(section, shortkeys.KEY_AJUDA, () => {
    window.open(LINK_AJUDA, '_blank')
  })
  addShortcut(section, shortkeys.KEY_MENUL, carregarNotificacoesComFiltro)
}

const _ligarAtalhosModalVisualizar = (LinkAjuda) => {
  const section = 'modalVisualizar'
  setActiveSection(section)
  addShortcut(section, shortkeys.KEY_ESC, fecharModais)
  addShortcut(section, shortkeys.KEY_AJUDA_ALTERNATIVA, () => {
    window.open(LinkAjuda, '_blank')
  })
  addShortcut(section, shortkeys.KEY_AJUDA, () => {
    window.open(LinkAjuda, '_blank')
  })
}

function fecharModais () {
  showModalInconsistenciasPlanoDeContas.value = false
  showModalGenericoVisualizar.value = false
  showModalGenericoVisualizarNovo.value = false
  identificadorOperacaoParaAbrirModalVisualizar.value = ''
  showModalInconsistenciasProcessamentoFolha.value = false
}

function _visualizarAjustesEmBloco (notificacao) {
  idParaObterNotificacaoCompleta.value = notificacao.id
  identificadorOperacaoParaAbrirModalVisualizar.value = Constantes.EDICAOEMBLOCO
  showModalGenericoVisualizar.value = true
}

const _visualizarPlanoDeContas = async (item) => {
  if (item.status === Constantes.ERRO) {
    idParaObterNotificacaoCompleta.value = item.id
    showModalInconsistenciasPlanoDeContas.value = true
  } else if (item.status === Constantes.SUCESSO) {
    const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(item.id)
    if (notificacaoCompleta.detalhe) {
      _acessarUrlPackonline(item)
    } else {
      console.error('Não foi possível obter o id do plano de contas para efetuar o redirecionamento da página.')
    }
  }
}

const _acessarUrlPackonline = async (item) => {
  const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(item.id)
  const objeto = JSON.parse(CentralDeNotificacoes.retornarDetalheFormatado(notificacaoCompleta.detalhe.toString()))
  const url = objeto.linkUrlPackonline
  router.push(url)
}

const statusProcessamentoFolha = ref('')

const _visualizarProcessamentoDaFolha = (item) => {
  if (item.status === Constantes.ERRO) {
    idParaObterNotificacaoCompleta.value = item.id
    showModalInconsistenciasProcessamentoFolha.value = true
    statusProcessamentoFolha.value = Constantes.ERRO
  } else if (item.status === Constantes.INCONSISTENCIA) {
    idParaObterNotificacaoCompleta.value = item.id
    showModalInconsistenciasProcessamentoFolha.value = true
    statusProcessamentoFolha.value = Constantes.INCONSISTENCIA
  }
}

const visualizarLog = async (item, operacao) => {
  idParaObterNotificacaoCompleta.value = item.id
  identificadorOperacaoParaAbrirModalVisualizar.value = operacao
  showModalGenericoVisualizar.value = true
}

const _visualizarImportacaoDeLancamentosDeFuncionarios = (item) => {
  idParaObterNotificacaoCompleta.value = item.id
  identificadorOperacaoParaAbrirModalVisualizar.value = Constantes.IMPORTACAODELANCAMENTOSANTERIORESFUNCIONARIOS
  showModalGenericoVisualizar.value = true

  if (item.status === Constantes.SUCESSO) {
    mostrarMensagemSucesso.value = true
    linkCliqueAqui.value = `${env.dp}/processamentos/lancamentos/funcionarios`
    mensagemCliqueAqui.value = 'Importação realizada. Para visualizar os lançamentos importados '
  } else {
    linkCliqueAqui.value = ''
    mensagemCliqueAqui.value = ''
  }
}

const _visualizarImportacaoDeLancamentosDeContribuintes = (item) => {
  idParaObterNotificacaoCompleta.value = item.id
  identificadorOperacaoParaAbrirModalVisualizar.value = Constantes.IMPORTACAODELANCAMENTOSANTERIORESCONTRIBUINTES
  showModalGenericoVisualizar.value = true

  if (item.status === Constantes.SUCESSO) {
    mostrarMensagemSucesso.value = true
    linkCliqueAqui.value = `${env.dp}/processamentos/lancamentos/socios-autonomos`
    mensagemCliqueAqui.value = 'Importação realizada. Para visualizar os lançamentos importados '
  } else {
    linkCliqueAqui.value = ''
    mensagemCliqueAqui.value = ''
  }
}

const _visualizarImportacaoDeRemuneracaoDoCadastroDeContribuintes = (item) => {
  idParaObterNotificacaoCompleta.value = item.id
  identificadorOperacaoParaAbrirModalVisualizar.value = Constantes.IMPORTACAODEREMUNERACAODOCADASTRO
  showModalGenericoVisualizar.value = true

  if (item.status === Constantes.SUCESSO) {
    mostrarMensagemSucesso.value = true
    linkCliqueAqui.value = `${env.dp}/processamentos/lancamentos/socios-autonomos`
    mensagemCliqueAqui.value = 'Importação realizada. Para visualizar as remunerações importadas '
  } else {
    linkCliqueAqui.value = ''
    mensagemCliqueAqui.value = ''
  }
}

const _realizarDownloadDaPlanilha = (item) => {
  PlanilhaCentralDeNotificacao.downloadItem(item)
}

const _realizarDownloadBalanco = async (item) => {
  return await BalanceteCentralDeNotificacao.downloadItem(item)
}

const _avaliarSeExisteGeracaoDeRelatorio = (item, objetoDetalhe) => {
  return (item.status === Constantes.SUCESSO && objetoDetalhe.linkDownload)
}

const _clickDinamicoDeAcordoComOTipoDeItem = async (item) => {
  const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(item.id)
  const objetoDetalhe = JSON.parse(CentralDeNotificacoes.retornarDetalheFormatado(notificacaoCompleta.detalhe.toString()))
  if (CentralDeNotificacoes._avaliarSeOretornoEstaNoFormatoNovo(objetoDetalhe)) {
    if (item.identificadorOperacao === Constantes.IMPORTACAOPLANO) {
      _visualizarPlanoDeContas(item)
    } else {
      if (_avaliarSeExisteGeracaoDeRelatorio(item, objetoDetalhe)) {
        await CentralDeNotificacoes.visualizarRelatorioGenericoNovo(item)
      } else {
        visualizarInconsistenciasGenerico(item)
      }
    }
  } else {
    // TODO: Remover todo esse bloco após a conversão de todos os processamentos
    switch (item.identificadorOperacao) {
      case Constantes.EXPORTACAOPLANILHA:
        _realizarDownloadDaPlanilha(item)
        break

      case Constantes.EDICAOEMBLOCO: {
        _visualizarAjustesEmBloco(item)
        break
      }

      case Constantes.IMPORTACAOPLANO: {
        _visualizarPlanoDeContas(item)
        break
      }
      case Constantes.PROCESSAMENTOFOLHA : {
        _visualizarProcessamentoDaFolha(item)
        break
      }
      case Constantes.IMPORTACAOPLANILHA: {
        _acaoDaImportacao(item)
        break
      }
      case Constantes.IMPORTACAODELANCAMENTOSANTERIORESFUNCIONARIOS : {
        _visualizarImportacaoDeLancamentosDeFuncionarios(item)
        break
      }
      case Constantes.IMPORTACAODELANCAMENTOSANTERIORESCONTRIBUINTES : {
        _visualizarImportacaoDeLancamentosDeContribuintes(item)
        break
      }
      case Constantes.RECIBOPAGAMENTO : {
        if (item.status === Constantes.SUCESSO) {
          await CentralDeNotificacoes.visualizarRelatorio(item)
        } else {
          await visualizarLog(item, Constantes.RECIBOPAGAMENTO)
        }
        break
      }
      case Constantes.FOLHAPAGAMENTO : {
        if (item.status === Constantes.SUCESSO) {
          await CentralDeNotificacoes.visualizarRelatorio(item)
        } else {
          await visualizarLog(item, Constantes.FOLHAPAGAMENTO)
        }
        break
      }
      case Constantes.BALANCO: {
        _acoesBalanco(item)
        break
      }
      case Constantes.IMPORTACAODEREMUNERACAODOCADASTRO : {
        _visualizarImportacaoDeRemuneracaoDoCadastroDeContribuintes(item)
        break
      }
    }
  }
}

function visualizarInconsistenciasGenerico (notificacao) {
  idParaObterNotificacaoCompleta.value = notificacao.id
  identificadorOperacaoParaAbrirModalVisualizar.value = Constantes.BALANCETE
  showModalGenericoVisualizarNovo.value = true
}

async function _acaoDaImportacao (notificacao) {
  const vaiParaUrl = await checarApontamentoParaURLancamento(notificacao)
  if (!vaiParaUrl) {
    identificadorOperacaoParaAbrirModalVisualizar.value = Constantes.IMPORTACAOPLANILHA
    idParaObterNotificacaoCompleta.value = notificacao.id
    showModalGenericoVisualizar.value = true
  }
}

async function checarApontamentoParaURLancamento (notificacao) {
  linkCliqueAqui.value = ''
  const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(notificacao.id)
  try {
    const jsonFile = FuncoesGenericas.retornarJsonPuro(notificacaoCompleta.detalhe)
    const logRotinas = jsonFile.logRotinasAutomaticas
    let processadoComSucessoSucesso = false
    if (notificacao.status === Constantes.SUCESSO) {
      processadoComSucessoSucesso = logRotinas.log.length === 1 && logRotinas.log.some(element => element.includes('Processado com sucesso'))
      const logPackOnline = jsonFile.informacoesComplementaresPackOnline
      if (logRotinas.gerado && processadoComSucessoSucesso) {
        const urlPainel = `${env.contabil}/lancamento-contabil/lancamento/${logPackOnline.idEmpresa}/lote/${logRotinas.id_lote}/?tipo=lote`
        window.open(urlPainel, '_self')
        return true
      }
      linkCliqueAqui.value = `${env.contabil}/lancamento-contabil/lancamento/${logPackOnline.idEmpresa}/lote/${logRotinas.id_lote}/?tipo=lote`
      mostrarMensagemFalha.value = false
      mostrarMensagemSucesso.value = true
      return false
    }
    mostrarMensagemFalha.value = !logRotinas.gerado && !logRotinas.arquivo_baixado
    mostrarMensagemSucesso.value = false
    return false
  } catch (error) {
    mostrarMensagemFalha.value = false
    mostrarMensagemSucesso.value = false
  }
}

function estaDesativado (item) {
  return item.status === Constantes.ANDAMENTO ||
                (item.identificadorOperacao === Constantes.EXPORTACAOPLANILHA && item.status === Constantes.ERRO)
}

function _acoesBalanco (item) {
  if (item.status === Constantes.ERRO) {
    visualizarInconsistenciasContabil(item, Constantes.BALANCO)
  } else {
    visualizarBalanco(item)
  }

  async function visualizarBalanco (item) {
    const urlBalancete = await _realizarDownloadBalanco(item)
    if (urlBalancete) {
      window.open(urlBalancete, '_blank')
    }
  }
}

function visualizarInconsistenciasContabil (notificacao, tipo) {
  idParaObterNotificacaoCompleta.value = notificacao.id
  identificadorOperacaoParaAbrirModalVisualizar.value = tipo
  showModalGenericoVisualizar.value = true
}

onMounted(async () => {
  _ligarAtalhosCentralNotificacoes()
  try {
    await carregarTodosUsuarios()
    await carregarTodasNotificacoes()
    await CentralDeNotificacao.atualizarNotificacoesComoVisualizadas(listaDeNotificacoesNaoVisualizadas.value)
  } catch (error) {
    status.value = 'is-danger'
    console.error(error)
  }
})
</script>

<style scoped>
.tag-importando {
  background-color: #f0e4b7 !important;
  color: #ab8a12 !important;
}

.dropdown-container {
  height: 80px;
  margin-bottom: 0.4rem;
}

</style>
