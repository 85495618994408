import { CentralDeNotificacao } from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/centralDeNotificacao.js'
import { service } from '@packonline/packonline-reutilizaveis-frontend/core/services/service'

class CentralDeNotificacoes {
  static async obterLinkDoArquivoNaAWS (item) {
    const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(item.id)
    const detalhe = JSON.parse(notificacaoCompleta.detalhe)
    const url = `/api/v1/arquivos/gerar-url/download?fileName=${detalhe.informacoesComplementaresPackOnline.nomeDoArquivo}`
    const response = await service.get(url)
    return response.url
  }

  static async visualizarRelatorio (item) {
    const url = await this.obterLinkDoArquivoNaAWS(item)
    if (url) {
      window.open(url, '_blank')
    }
  }

  static async obterLinkDoArquivoNaAWSGenericoNovo (item) {
    const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(item.id)
    const objeto = JSON.parse(this.retornarDetalheFormatado(notificacaoCompleta.detalhe.toString()))
    const url = `/api/v1/arquivos/gerar-url/download?fileName=${objeto.linkDownload}`
    const response = await service.get(url)
    return response.url
  }

  static async visualizarRelatorioGenericoNovo (item) {
    const url = await this.obterLinkDoArquivoNaAWSGenericoNovo(item)
    if (url) {
      window.open(url, '_blank')
    }
  }

  static retornarDetalheFormatado (detalhe) {
    return detalhe.replace(/\r\n/g, '<br>')
  }

  static _avaliarSeOretornoEstaNoFormatoNovo (json) {
    const chavesEsperadasDoJsonDeRetorno = ['linkDownload', 'linkUrlPackonline', 'tituloModal', 'mensagemAmigavel', 'logRetorno']

    const chavesDoJsonDeRetorno = Object.keys(json)
    return chavesEsperadasDoJsonDeRetorno.every(key => chavesDoJsonDeRetorno.includes(key))
  }
}

export { CentralDeNotificacoes }
