<template>
  <Modal :title="tituloModal" :active="modalActive" size="normal" width="768px" @close="cancelar()">
    <slot name="acimaBoxInconsistencia" />
    <div v-if="props.exibeBoxDetalhe" id="detalhesModal" data-group="box-detalhe-inconsistencia" ref="modal" style="outline: none" class="container detalhes">
      <p data-group="txt-detalhe-inconsistencia" style="text-wrap: pretty" v-html="detalheModal"></p>
    </div>
    <template #footer>
      <ButtonAjuda link-ajuda="https://ajuda.alterdata.com.br/display/POD/Central+de+Notificacoes" title="Atalho: ALT + ?" style="margin-right: auto" />
      <Button title="Atalho: Esc" caption="Fechar" class="button" @click="cancelar()" />
    </template>
  </Modal>
</template>

<script setup>

import { ref, defineEmits, defineProps, onMounted } from 'vue'

import Modal from '@alterdata/component-vue/Modal'
import Button from '@alterdata/component-vue/Button'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import { CentralDeNotificacao } from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/centralDeNotificacao.js'

const props = defineProps({
  idProcesso: {
    type: Number,
    required: true
  },
  modalActive: {
    type: Boolean,
    default: false
  },
  exibeBoxDetalhe: {
    type: Boolean,
    default: true
  },
  identificadorOperacao: {
    type: String,
    required: true
  }
})
const emit = defineEmits(['cancelar'])

const tituloModal = ref('')

const detalheModal = ref('')

onMounted(() => {
  if (props.exibeBoxDetalhe) {
    _carregarDetalhesDeAcordoComOIdentificadorDaOperacao()
  }
})

async function _carregarDetalhesDeAcordoComOIdentificadorDaOperacao () {
  const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(props.idProcesso)
  if (verificarSeEOformatoEsperado(retornarDetalheFormatado(notificacaoCompleta.detalhe.toString()))) {
    const objeto = JSON.parse(retornarDetalheFormatado(notificacaoCompleta.detalhe.toString()))
    objeto.logRetorno = JSON.parse(objeto.logRetorno)
    detalheModal.value = objeto.logRetorno.logRotinasAutomaticas.log
    tituloModal.value = objeto.tituloModal
  } else {
    detalheModal.value = 'O objeto não retornou no formato esperado!'
    tituloModal.value = 'erro'
  }
}

function retornarDetalheFormatado (detalhe) {
  return detalhe.replace(/\r\n/g, '<br>')
}

function cancelar () {
  emit('cancelar')
}

function verificarSeEOformatoEsperado (obj) {
  try {
    // Tenta fazer o parse do texto para JSON
    const jsonObj = JSON.parse(obj)

    // Verifica se as propriedades esperadas existem e têm o formato correto
    const hasRequiredProperties =
      typeof jsonObj.linkDownload === 'string' &&
      typeof jsonObj.linkUrlPackonline === 'string' &&
      typeof jsonObj.tituloModal === 'string' &&
      typeof jsonObj.mensagemAmigavel === 'string' &&
      typeof jsonObj.logRetorno === 'string'

    return hasRequiredProperties
  } catch (e) {
    // Se houver qualquer erro no parse ou na verificação, retorna falso
    return false
  }
}

</script>

<style lang="scss" scoped>
@use '@alterdata/theme-bulma';

.detalhes {
  background-color: #f5f5f5;
  border: 1px solid #d0d0d0;
  min-height: 12.5rem;
  max-height: 20rem;
  white-space: nowrap;
  text-align: left;
  padding: 1rem;
  overflow: auto;
}
</style>
