export class FuncoesGenericas {
  static retornarJsonPuro (item) {
    return JSON.parse(item.replace(/\\/g, '\\\\'))
  }

  static transformarLinhasDoLog (log) {
    let newLog = ''
    if (Array.isArray(log)) {
      log.forEach(element => {
        const newElement = element.substring(0, element.length)
        newLog += (newElement.toString() + '<br><br>')
      })
    } else if (typeof log === 'string') {
      newLog = `${log}<br><br>`
    }
    return newLog
  }
}
