<template>
  <Modal
    title="Inconsistências do processamento"
    size="normal"
    style="z-index: 1000"
    width="1000px"
    :active="true"
    @close="cancelar">
    <div v-if="statusProcessamentoFolha === 'FINALIZADO_COM_ERRO'" class="pr-0" style="margin-right: -3px">
      <p class="paragraph" data-group="text-processamento-folha-erro" style="max-width: 60rem" v-html="logInconsistencias"></p>
    </div>
    <div v-if="statusProcessamentoFolha === 'FINALIZADO_COM_INCONSISTENCIA'" class="pr-0" style="margin-right: -3px">
      <p class="pb-2">Processamento finalizado. Para visualizar o relatório, <a>clique aqui</a>.</p>
      <p class="pb-2">Verifique as inconsistências abaixo:</p>
      <p class="paragraph" data-group="text-processamento-folha-erro" style="max-width: 60rem" v-html="logInconsistencias"></p>
    </div>
    <template v-slot:footer>
      <ButtonAjuda
        :title="shortkeys.MSG_KEY_AJUDA"
        :link-ajuda="LINK_AJUDA"
      />
      <Button
        :title="shortkeys.MSG_KEY_ESC"
        class="button"
        caption="Fechar"
        @click="cancelar"
      />
    </template>
  </Modal>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref } from 'vue'
import Modal from '@alterdata/component-vue/Modal'
import Button from '@alterdata/component-vue/Button'
import ButtonAjuda from '@packonline/packonline-reutilizaveis-frontend/components/ButtonAjuda.vue'
import { shortkeys } from '@packonline/packonline-reutilizaveis-frontend/Utils/shortkeys'
import { CentralDeNotificacao } from '@packonline/packonline-reutilizaveis-frontend/components/centralDeNotificacoes/centralDeNotificacao.js'

const emit = defineEmits(['cancelar'])

const LINK_AJUDA = 'https://ajuda.alterdata.com.br/display/POD/Processar+Folha+de+Pagamento'

const props = defineProps({
  idProcessamentoFolha: {
    type: Number,
    required: true
  },
  statusProcessamentoFolha: String
})

const logInconsistencias = ref('')

async function definirDadosIniciais () {
  try {
    const notificacaoCompleta = await CentralDeNotificacao.retornarNotificacaoCompletaPorId(props.idProcessamentoFolha)
    logInconsistencias.value = retornarObjJsonFormatado(notificacaoCompleta.detalhe)
  } catch (err) {
    console.error(err)
  }
}

function retornarObjJsonFormatado (detalhe) {
  const jsonArray = JSON.parse(detalhe)
  if (Array.isArray(jsonArray)) {
    return jsonArray.join('<br><br>')
  } else {
    return jsonArray.logRotinasAutomaticas
  }
}

function cancelar () {
  emit('cancelar')
}

onMounted(() => {
  definirDadosIniciais()
})
</script>

<style lang="scss" scoped>
@use '@alterdata/theme-bulma';
.paragraph {
  font-size: 14px;
  font-weight: 400;
  background-color: whitesmoke;
  border-color: lightgray;
  border-width: 1px;
  border-style: solid;
  height: 30vh;
  padding-inline: 18px;
  padding-top: 1rem;
}
</style>
